<template>
  <base-section
    id="map"
    space="0"
  >
    <iframe
      allowfullscreen
      aria-hidden="false"
      frameborder="0"
      height="500"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1446.6111229537514!2d16.46394634114514!3d43.51855799385068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13355e3fd4deeea3%3A0xd00d4df4cfae6832!2sMose%C4%87ka%20ul.%2095%2C%2021000%2C%20Split!5e0!3m2!1sen!2shr!4v1593641867227!5m2!1sen!2shr"
      style="border:0; display: block;"
      tabindex="0"
      width="100%"
    />
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMap',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
